<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'orders-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
      <div class="col-12 col-md-6 col-lg-auto col-xl-auto">
        <button
          @click="printManyPurchaseOrder(selections)"
          class="btn btn-secondary"
        >
          <i class="bi bi-printer"></i>
          Imprimer Bon de Commannde (Selectionnee)
        </button>
      </div>
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom Complet</th>
          <th scope="col">Date</th>
          <th scope="col">Montant Total</th>
          <th scope="col">Total Quantité</th>
          <th scope="col">Etat</th>

          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, index) in orders" :key="index++">
          <td>
            <input
              class="form-check-input"
              type="checkbox"
              :id="order.reference"
              :value="order.reference"
              v-model="selections"
            />
            {{ index }}
          </td>
          <td>
            {{ order.reference }}
          </td>
          <td>
            <span v-if="order.customer">
              {{ order.customer.fullName }}
            </span>
          </td>
          <td>{{ order.date }}</td>
          <td>
            <span v-if="order.totalPriceTTC != null" class="">
              {{ order.totalPriceTTC }}
            </span>
           
          </td>
          <td>
            <span v-if="order.totalQuantity != null">
              {{ order.totalQuantity }}
            </span>
          </td>

          <td>
            <span
              v-if="order.checked_at != null"
              class="px-2 py-1 bg-info rounded"
            >
              Vérifié à : {{ order.checked_at }}
            </span>

            <span
              v-else-if="order.charged_at != null"
              class="px-2 py-1 bg-warning rounded"
            >
              Chargé à : {{ order.charged_at }}
            </span>

            <span
              v-else-if="order.delivered_at != null"
              class="px-2 py-1 bg-success rounded"
            >
              Livré à : {{ order.delivered_at }}
            </span>

            <span
              v-else-if="order.canceled_at != null"
              class="px-2 py-1 bg-danger rounded"
            >
              Annulé à : {{ order.canceled_at }}
            </span>
            <span v-else class="bg-warning px-2 py-1 rounded">
              Vérification en attente
            </span>
          </td>

          <td>
            <span v-if="order.user">
              {{ order.user.name }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'orders-details',
                  params: { reference: order.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      selections: [],
    };
  },
  computed: {
    ...mapGetters("order", {
      orders: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("order/getAll");

    // setInterval(() => {
    //   this.$store.dispatch("order/getAll");
    // }, 3000);
  },
  methods: {
    async search(value) {
      return await this.$store.commit("order/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("order/getAll");
    },

    async listOrders(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("order/getTodays");
      } else {
        return await this.$store.dispatch("order/getOrders");
      }
    },
    async sort(value) {
      return await this.$store.commit("order/sort", value);
    },

    async printManyPurchaseOrder(data) {
      await this.$store.dispatch("order/printManyPurchaseOrderPage", data);
    },
  },
};
</script>
